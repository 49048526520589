.badge-detail-Patient {
    font-size: 75%;
    font-weight: 600;
    line-height: 1;
    display: inline-block;
    padding: .35em .4em;
    text-align: center;
    vertical-align: initial;
    white-space: nowrap;
    border-radius: .21rem;
    background-color: #10dfdb !important;
    border-radius: 5px !important;
    color: #fff;
    place-content: center;
}

.badge-detail-Patient-M {
    font-size: 75%;
    font-weight: 600;
    line-height: 1;
    display: inline-block;
    padding: .35em .4em;
    text-align: center;
    vertical-align: initial;
    white-space: nowrap;
    border-radius: .21rem;
    background-color: #ff05d763 !important;
    border-radius: 5px !important;
    color: #fff;
    place-content: center;
}


