@media screen and (max-width: 1400px) {
    .modal {
        width: 90% !important;
        max-width: none !important;
        height: 110vh !important;
        position: absolute !important;
        top: 45% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}



@media screen and (max-width: 1024px) {
    .modal {
        width: 100% !important;
        max-width: none !important;
        height: 100vh !important;
        margin: 0 auto !important;
        top: 45% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}



@media screen and (max-width: 768px) {
    .modal {
        width: 100% !important;
        max-width: none !important;
        margin: 0 auto !important;
        height: 100vh !important;
        top: 45% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}

@media screen and (max-width: 576px) {
    .modal {
        width: 100% !important;
        max-width: none !important;
        margin: 0 auto !important;
        height: 100vh !important;
        top: 45% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}