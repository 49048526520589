.fondoCard {
    background-color: #eaeaea;
}

.iconProfile {
    color: #0079C7;
}


.scrollHistoryPatient {
    width: 870px;
    height: 473px;
    overflow: auto;
  }
