
.fc-header-toolbar button {
    background-color: rgb(3, 61, 91) !important;
    color: #ffffff; 
    border-color:  rgb(3, 61, 91) !important;
  }

  .fc-col-header-cell-cushion {
    color: rgb(3, 61, 91) !important;
  }

  .fc-toolbar-title {
    color: rgb(3, 61, 91) !important;
  }


  .fc.fc-theme-standard .fc-highlight {
    background-color: #00294040 !important;
    box-shadow: 0 0 10px rgb(0 0 0 / 60%) !important;
    color: #ffffff !important;
}



    .fc.fc-theme-standard .fc-toolbar .fc-button {
        color: #ffffff !important;
    }

    .applyBtn {
        background-color:  rgb(3, 61, 91) !important;
    }

    .cancelBtn {
        background-color: #dc3545!important;
        color: #ffffff !important;
    }


    .drp-selected {
        color: rgb(3, 61, 91) !important;
        font-size: 16px !important;
    }

    .bg-calender {
        background-color: rgb(3, 61, 91) !important;
        color: #ffffff !important;
    }


    .progtrckr-doing {
        color: #46AFE5 !important;
        font-size: 14px !important;
    }


    .progtrckr-done {
        color: #28a745 !important;
        font-size: 14px !important;

    }


    .alert-color {
        background-color: #dc35451a !important;
   
    }

    .event-color-red {
        background-color: red !important;
      }
      
      .event-color-blue {
        background-color: blue !important;
      }



/* fc-event, .fc-event-dot {
    background-color: #2a4e60 !important;
    border-color: var(--theme-deafult) !important;
    padding: 7px !important;
    color: #fff !important;
} */


.fc-event {
    background-color: #2a4e60 !important;
    border-color: var(--theme-deafult) !important;
    padding: 7px !important;
    color: #fff !important;
}


.color-data {
    color: rgb(3, 61, 91) !important;
}


.table-wrapper {
    width: 100%;
    height: 400px;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 41, 64, .5803921568627451) transparent;
}


/* Estilos para la tabla */
.custom-table th {
    font-weight: bold;
  }
  
  /* Ancho para cada columna */
  .custom-table th:nth-child(1) {
    width: 20%; /* Porcentaje del ancho total de la tabla */
    /* text-align: center; */
  }
  
  .custom-table th:nth-child(2) {
    width: 30%; /* Porcentaje del ancho total de la tabla */
    /* text-align: center; */
  }
  
  .custom-table th:nth-child(3) {
    width: 27%; /* Porcentaje del ancho total de la tabla */
    /* text-align: center; */
  }
  
  .custom-table th:nth-child(4) {
    width: 23%; /* Porcentaje del ancho total de la tabla */
    /* text-align: center; */
  }


  .buttong {
    background-color: #aaa7a7; 
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 60px;
    
  }
 
  
  .buttong-hovered {
    background-color: #7d7c7c;
  }

  .border-range-programming {
    background-color: #f1f2f3!important;
    border: 1px solid rgb(235, 243, 246) !important;
}

.deni-react-treeview-container-4 {
  font-family: tahoma, arial;
  font-size: 12px;
  padding: 0px;
  overflow-x: auto;
  overflow-y: auto;
  border: solid 1px;
  border-color: #a5c7e3;
  width: 400px;
  height: 350px;
  background-color: #f1f2f3;
}


.color-data-0 {
  color: red !important;
}

.color-data-1 {
  color: blue !important;
}


.table-wrapper-event {
  width: 100%;
  height: 405px; /* Altura de ejemplo */
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #00294094 rgba(0, 0, 0, 0);
}

.table-wrapper-event table thead {
  position: -webkit-sticky; /* Safari... */
  position: sticky;
  top: 0;
  left: 0;
}
.sticky-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: #033d5b; 
  z-index: 1; 
}

.buttong-1 {
  background-color: #11003d; 
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 60px;
  
}

.buttong-hovered-2 {
  background-color: #02000f;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 60px;
}

.tap-top-Serie {
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  display: none;
  height: 40px;
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 5;
  color: #fff;
  background: #dc3545;
  border: none;
  padding: 8px;
  box-shadow: 0 0 10px var(--theme-deafult);
  opacity: 5;
  transition: all 0.3s ease;
}

.card-detail-patien {
  padding: 15px;
  background-color: transparent;
  flex:  1 1 auto;
  min-height: 1px;
}

.shadow-detail-patient
{
  box-shadow: 1px 0rem 2rem rgba(0, 0, 0, 0.15) !important;
}

.hr-detail-patient {
  border-bottom: solid 2px rgb(12 108 126);
}

.calender-deatil-branches-nada {
  color: #BDBDBD;
}
.calender-deatil-branches-bajo {
  color: #FF0000;
}
.calender-deatil-branches-medio-b {
  color: #FFFB46;
}
.calender-deatil-branches-medio {
  color: #FFA500;
}
.calender-deatil-branches-alto {
  color: #00FF00;
}
.calender-deatil-branches-alto-m {
  color: #1da750;
}

.branches-label {
  color: #033f60;

}


.badge-branches-view {
  font-size: 85%;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  padding: 0.35em 0.4em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  border-radius: 0.21rem;
  background-color: #0e245e;
  color: white;
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
  box-shadow: 0 0 10px #1f9ad9
}

.opacity-patient-detail {
  filter: blur(1px);
}


.gauge-container {
  position: relative;
  width: 200px;
  height: 200px;
}

.gauge-svg {
  width: 100%;
  height: 100%;
}

.gauge-background {
  fill: transparent;
  stroke: #ccc;
  stroke-width: 10;
}

.gauge-arc {
  fill: transparent;
  stroke: green;
  stroke-width: 10;
  transition: stroke-dasharray 0.5s ease;
  stroke-linecap: round;
}

.gauge-pointer {
  color: red;
}

.gauge-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #070523;
}

.gauge-text {
  text-anchor: middle;
  font-size: 16px;
  fill: #070523;
}

.branch-toggle {
  background-color: inherit; /* o el color que desees */
}

.border-primary-input {
  border-color: #205f82 !important;
}


.bg-info-programming {
  background-color: #114766 !important;
  color: #fff;
}


.contenedor-prueba-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh;  */
}

.valdidate-color {
  background-color: #ff00001a;
}

.status-patient {
  color: #ff0018;
  font-size: 12px;
}

.shadow-detail-patient {
  box-shadow: -10px 1rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-detail-patient-table {
  box-shadow: 3px 1rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.user-profile .hovercard .user-image .share-icons {
  position: absolute;
  right: 1px !important;
  top: -27px;
}

.icon-div-user {
  width: 50px;
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  border-radius: 100%;
}


.button-filter {
  background-color:rgb(1, 12, 18); 
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 60px;
  
}

.button-filter-hovered {
  background-color: #4f4c4c;
}


.button-filter-d {
  background-color:#55595c;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 60px;
  
}

.button-filter-d-hovered {
  background-color: #c3bfbfe1;
}

.label-Filter {
  color: #55595c7a;
}
























