@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.input-icons i {
  position: absolute;
}

.my-swal {
  z-index: 30000 !important;
}

.input-icons {
  height: 37px !important;
  margin-top: 1px !important;
  right: 40px;
  width: 300px;
}

.icon {
  padding: 10px;
  color: #ffffff;
  min-width: 50px;
  text-align: center;
}

/* aqui va el placeholder */
.input-field {
  width: 92%;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
  border-radius: 20px;
  /* background-color: rgba(94, 187, 248, 0.5); */
  background-color: rgb(73 148 197 / 50%);

  /* opacity: .5; */
}


#pnombre::placeholder {
  color: #ffffff;
}

.tamañoinput {
  height: 45px !important;
  margin-top: 1px !important;
  right: 40px;
  width: 300px;
  text-align-last: center;
  border-radius: 4px;
  border-radius: 20px;
  /* background-color: rgba(94, 187, 248, 0.5); */
  background-color: rgb(53 162 233 / 50%);
  ;
  /* opacity: .5;   */
  /* mover aqui */
  color: #FFF;
}

.tamañoButton {
  width: 80px;
  height: 50px;
}




.ppp {
  color: #FF00AA;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.ii {
  color: #FF00AA;
}

.incorrect-loginn {
  width: 100%;
  color: #ffffff;
  margin: 0 auto;
  font-size: 1rem;
}


/* este Login es la primera propuesta */
/* .bg-toma
{
  background-image: url('../images/login/loginTm.svg');
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  z-index: -50;
} */

/* rosa */
/* 
.bg-toma {
  background-image: url('../images/login/loginTm2.svg');
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  z-index: -50;
} */

/* .bg-toma {
  background-image: url('../images/loginHelenLabs/TomaDeMuestra/LoginHelenLabsToma.jpg');
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  z-index: -50;
}  */

.bg-toma {
  background-image: url('../images/loginHelenLabs/TomaDeMuestra/LoginCardInput.png');
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  z-index: -50;
}

.bg-seguimiento {
  background-image: url('../images/loginHelenLabs/TomaDeMuestra/LoginHelenLabsToma.jpg');
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  z-index: -50;
}


.tap-top-B {
  text-align: center;
  cursor: pointer;
  display: none;
  width: 160px;
  height: 35px;
  position: fixed;
  bottom: 30px;
  /* right: 30px; */
  z-index: 5;
  color: #fff;
  background: var(--theme-deafult);
  border-color: #FFF;

  padding: 7px;
  box-shadow: 0 0 10px #FFF;

  /* box-shadow: 0 0 10px var(--theme-deafult); */
  opacity: 10;
  transition: all 0.3s ease;
}

.tap-top-regresar {
  text-align: center;
  cursor: pointer;
  display: none;
  width: 160px;
  height: 35px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 5;
  color: #fff;
  background: #55a8d3;
  border-color: #FFF;
  padding: 7px;
  box-shadow: 0 0 10px #fff;
  /* box-shadow: 0 0 10px var(--theme-deafult); */
  opacity: 10;
  transition: all 0.3s ease;
}

.imgCartT {
  width: 12%;
}

.imgCardT2 {
  width: 25%;
}




.showP {
  font-family: FontAwesome;
  color: #b42b80;
  content: "\f070 ";
}

.sel {
  background-color: #ffaed6;
}

@media screen and (max-width: 1920.99px) {
  .imglogoHelen {
    width: 10% !important;
  }

  .imglogoHelenSeguimiento {
    width: 40% !important;
  }

  .imgCardF {
    width: 15% !important;
  }

}


@media screen and (max-width: 1460px) {
  .imglogoHelen {
    width: 10% !important;
  }

  .imgCardF {
    width: 15% !important;
  }

  .imgFlecha {
    width: 50% !important;
  }

  .pp {
    color: #FFf;
    font-size: 14px !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: center;
    border-radius: 18px;
  }

  .imgSatisfactionQ {
    width: 75% !important;
  }

  .label-SatisfactionQuestion {
    color: #000000;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
  }

  .imglogCronometro {
    width: 45% !important;
  }

  .labelPatientToma {
    font-size: 28px;
  }

  .labelStudyToma {
    font-size: 14px !important;
  }

  .labelStudyExamToma {
    font-size: 16px !important;
  }

  .imgModalSuspensive {
    width: 6% !important;
  }

  .labelPatientObservactions {
    font-size: 14px !important;

  }






}

/* dna size  */

@media screen and (max-width: 1024px) {
  .labelPatientObservactions {
    font-size: 13px !important;

  }

  .labelStudyToma {
    font-size: 14px !important;
  }

  .labelPatientToma {
    font-size: 20px !important;
  }






}






@media screen and (max-width: 1199.5px) {
  .imglogoHelen {
    width: 10% !important;
  }

  .pp {
    color: #FFf;
    font-size: 18px !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: center;
    border-radius: 18px;
  }

  .imgSatisfactionQ {
    width: 25% !important;
  }

  .label-SatisfactionQuestion {
    color: #000000;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
  }

  .imglogCronometro {
    width: 45% !important;
  }

  .labelPatientToma {
    font-size: 22px;
  }

  .labelStudyToma {
    font-size: 20px !important;
  }

  .labelStudyExamToma {
    font-size: 20px !important;
  }

  .imgFlecha {
    width: 40% !important;
  }



}

/* size tablet */

@media screen and (max-width: 1130px) {
  .imglogoHelen {
    width: 13% !important;
  }

  .pp {
    color: #FFf;
    font-size: 12px !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: center;
    border-radius: 18px;
  }

  .imgSatisfactionQ {
    width: 85% !important;
  }

  .label-SatisfactionQuestion {
    color: #000000;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
  }

  .imglogCronometro {
    width: 45% !important;
  }

  .labelPatientToma {
    font-size: 28px;
  }

  .labelStudyToma {
    font-size: 14px !important;
  }

  .labelStudyExamToma {
    font-size: 13px !important;
  }

  .imgModalSuspensive {
    width: 7% !important;
  }

  .imgFlecha {
    width: 41% !important;
  }


}

@media screen and (max-width: 575.99px) {

  .imglogoHelen {
    width: 20% !important;
  }

  .labelNewSite {
    font-size: 10px !important;
  }


}


/* biologia molecular */