.multiselect-demo .p-multiselect {
    min-width: 15rem;
    z-index: 99999 !important;
}

.multiselect-demo .multiselect-custom .p-multiselect-label:not(.p-placeholder):not(.p-multiselect-items-label) {
    padding-top: .25rem;
    padding-bottom: .25rem;
    z-index: 99999 !important;

}

.multiselect-demo .multiselect-custom .country-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .5rem;
    background-color: "#0079c7";
    color: var(--primary-color-text);
    z-index: 99999 !important;

}

.multiselect-demo .multiselect-custom .country-item-value img.flag {
    width: 100%;
    z-index: 99999 !important;

}
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #0079C7 !important;
    background: #0079C7 !important;
}                
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
    color: hsl(0, 0%, 50%) !important;
}
.p-multiselect:not(.p-disabled).p-focus{
    border-color: #0079C7 !important;
}
.p-multiselect:not(.p-disabled):hover{
    border-color: #0079C7 !important;
}
.clase_desabilita{
    pointer-events: none !important;
}
.p-component{
    z-index: 99999 !important;

}





@media screen and (max-width: 768px) {
    .multiselect-demo .p-multiselect {
        font-size: 0.8rem; 
        margin-bottom: 1rem; 
    }
}