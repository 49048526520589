.Timmepicker {
  height: 37px !important;
  margin-top: 7px !important;
}

.Select {
  height: 37px !important;
  margin-top: 10px !important;
}

.boton {
  background: red;
}

img.a {
  vertical-align: baseline;
}

.PriceS {
  height: 37px !important;
  margin-top: 7px !important;
}

.SelectExam {
  height: 37px !important;
  margin-top: 7px !important;
}

img.opacity {
  opacity: 2;
}

img.opacity:hover {
  opacity: 2;
}

.badge-detail-external {
  font-size: 100%;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  padding: 0.35em 0.4em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  border-radius: 0.21rem;
  background-color: #29a5ebd6 !important;
  border-radius: 5px !important;
  color: #FFF;
  place-content: center;
}


.badge-detail-external-vigente {
  font-size: 100%;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  padding: 0.35em 0.4em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  border-radius: 0.21rem;
  background-color: #04b4c0d6 !important;
  border-radius: 5px !important;
  color: #FFF;
  place-content: center;
}