.modal-position {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .ca1 {
    color: rgb(0, 0, 0);
  }

  .bg-custom{
    background-color: #0079C7;
  }