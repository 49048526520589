.contenedor-prueba {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
}


.imagen-1 {
    max-width: 100%;
    max-height: 100%; 
}