/* .menu-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
  
  .menu-item {
    flex: 1;
    min-width: 150px;
    padding: 10px;
    text-align: center;
  }
  
  .menu-link {
    display: block;
    padding: 8px 16px;
    background-color: #f1f1f1;
    margin: 5px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .menu-link:hover {
    background-color: #ddd;
  }
  
  @media (orientation: landscape) {
    .menu-container {
      max-width: 100vw;
    }
  }
   */

   .sidebar-wrapper {
    position: fixed;
    width: 250px;
    background-color: #f8f9fa;
    height: 100%;
    overflow-y: auto; /* Permitir scroll */
  }
  
  .sidebar-menu {
    display: flex;
    flex-direction: column;
  }
  
  .menu-item {
    padding: 10px 15px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
  }
  
  .submenu {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    padding-left: 10px;
    border-left: 2px solid #ddd;
  }
  
  .submenu-item {
    padding: 5px 0;
  }
  
  .submenu {
    display: block; /* Cambia esto a 'none' cuando esté cerrado */
}
  
  /* Para pantallas más pequeñas o en horizontal */
  /* @media (max-width: 768px) {
    .sidebar-wrapper {
      width: 100%;
      position: relative;
      height: auto;
      overflow-y: auto;
    }
  
    .horizontal-mode {
      position: fixed;
      width: 250px;
      height: 100%;
    }
  } */

  