.Range {
  background-color: #b831f7;
  border-radius: 50px;
  -webkit-appearance: none;
}

.table-scroll {
  height: 110px;
  /*190*/
  overflow: auto;
  margin-top: 0px;
  /*20*/
  display: block;
}

.header_fijo {
  width: 941px;
  /*1100*/
  table-layout: fixed;
  border-collapse: collapse;
}

.header_fijo thead tr {
  display: block;
  position: relative;
}

.header_fijo tbody {
  display: block;
  overflow: auto;
  width: 941px;
  /*1100*/
  height: 190px;
  /* 200*/
}

.scroll_horizontal {
  width: 1380px;
  /*1380*/
  overflow: auto;
  padding: 8px;
}

.header_fijo_pmv tbody {
  display: block;
  overflow: auto;
  height: 200px;
  /* 190*/
}

.colorbutton {
  color: royalblue;
}

.header {
  background-color: rgb(247, 246, 248);
}

.th {
  background-color: rgb(181, 81, 240);
}

.colorprofile {
  color: rgba(210, 105, 30, 0.699);
}

.gato {
  height: 355px;
  overflow: auto;
}

.gato::-webkit-scrollbar {
  display: none;
}

.input-reason {
  width: 400px;
  height: 38px;
}

.input-section {
  width: 265px;
  height: 38px;
}

.labelPerdida{
  color: #D8263E;
}