.containerZ {
  z-index: 20 !important;
}

.scrollAnalytes {
  width: 440px;
  height: 530px;
  overflow: auto;
}

.InputFilterExam {
  margin-top: 1px !important;
  width: 160px !important;
  height: 38px !important;
}

.SelectFilterExam {
  margin-top: 1px !important;
  width: 185px !important;
  height: 38px !important;
}

.kit-icon {
  color: #0079C7;

}


@media screen and (max-width: 1460px) {

  .InputFilterExam {
    margin-top: 1px !important;
    width: 160px !important;
    height: 38px !important;
  }

  .SelectFilterExam {
    margin-top: 1px !important;
    width: 185px !important;
    height: 38px !important;
  }

  .labelCreateGeneral {
    font-size: 15px !important;
  }

  .labelExamNameSimple {
    font-size: 14px !important;
  }

  .labelUpdateEdit {
    font-size: 15px !important;
  }

  .labelCreateExa {
    font-size: 15px !important;
  }

  .labelMenuItem {
    font-size: 12px !important;
  }

  .labelgoBack {
    font-size: 14px !important;
  }

  .labelInfoAnalyte {
    font-size: 13px !important;
  }

  .labelAddMethods {
    font-size: 13px !important;
  }

  .labelnamemethod {
    font-size: 11px !important;
  }

  .labelSamplingg {
    font-size: 11px !important;
  }

  .labelConfigurationMethod {
    font-size: 10px !important;
  }

  .labelIntegracionAnalyte {
    font-size: 15px !important;
  }

  .labelTypeAnalyte {
    font-size: 12px !important;
  }

  .labelConfigurationPrint {
    font-size: 12px !important;
  }

  .labelAnalytes {
    font-size: 11px !important;
  }






}

@media screen and (max-width: 1199.5px) {
  .labelCreateGeneral {
    font-size: 15px !important;
  }

  .labelExamNameSimple {
    font-size: 14px !important;
  }

  .labelUpdateEdit {
    font-size: 15px !important;
  }

  .labelCreateExa {
    font-size: 15px !important;
  }

  .labelMenuItem {
    font-size: 12px !important;
  }

  .labelgoBack {
    font-size: 14px !important;
  }

  .labelInfoAnalyte {
    font-size: 13px !important;
  }

  .labelAddMethods {
    font-size: 10px !important;
  }

  .labelnamemethod {
    font-size: 10px !important;
  }

  .labelSamplingg {
    font-size: 10px !important;
  }

  .labelConfigurationMethod {
    font-size: 10px !important;
  }

  .labelIntegracionAnalyte {
    font-size: 10px !important;
  }

  .labelTypeAnalyte {
    font-size: 10px !important;
  }

  .labelConfigurationPrint {
    font-size: 11px !important;
  }

  .labelAnalytes {
    font-size: 11px !important;
  }




}

@media screen and (max-width: 1130px) {

  .InputFilterExam {
    margin-top: 1px !important;
    width: 230px !important;
    height: 38px !important;
  }

  .SelectFilterExam {
    margin-top: 1px !important;
    width: 250px !important;
    height: 38px !important;
  }

  .labelCreateGeneral {
    font-size: 13px !important;
  }

  .labelExamNameSimple {
    font-size: 12px !important;
  }

  .labelUpdateEdit {
    font-size: 13px !important;
  }

  .labelCreateExa {
    font-size: 13px !important;
  }

  .labelMenuItem {
    font-size: 11px !important;
  }

  .labelgoBack {
    font-size: 10px !important;
  }

  .labelInfoAnalyte {
    font-size: 11px !important;
  }

  .labelAddMethods {
    font-size: 10px !important;
  }

  .labelnamemethod {
    font-size: 10px !important;
  }

  .labelSamplingg {
    font-size: 10px !important;
  }

  .labelConfigurationMethod {
    font-size: 10px !important;
  }

  .labelIntegracionAnalyte {
    font-size: 10px !important;
  }

  .labelTypeAnalyte {
    font-size: 10px !important;
  }

  .labelConfigurationPrint {
    font-size: 11px !important;
  }

  .labelAnalytes {
    font-size: 11px !important;
  }






}



@media screen and (max-width: 575.99px) {

  .labelCreateGeneral {
    font-size: 13px !important;
  }

  .labelExamNameSimple {
    font-size: 12px !important;
  }

  .labelUpdateEdit {
    font-size: 13px !important;
  }

  .labelCreateExa {
    font-size: 13px !important;
  }

  .labelMenuItem {
    font-size: 11px !important;
  }

  .labelgoBack {
    font-size: 10px !important;
  }

  .labelInfoAnalyte {
    font-size: 11px !important;
  }

  .labelAddMethods {
    font-size: 10px !important;
  }

  .labelnamemethod {
    font-size: 10px !important;
  }

  .labelSamplingg {
    font-size: 10px !important;
  }

  .labelConfigurationMethod {
    font-size: 10px !important;
  }

  .labelIntegracionAnalyte {
    font-size: 10px !important;
  }

  .labelTypeAnalyte {
    font-size: 10px !important;
  }

  .labelConfigurationPrint {
    font-size: 11px !important;
  }

  .labelAnalytes {
    font-size: 11px !important;
  }




}