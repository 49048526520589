.inputCash {

    border: none;
    border-bottom: 1px solid #005a94;
}

.borderColo {
    border-color: #005a94;
    border-width: 2px;
    border-style: solid;
}

.borderClient {
    border: rgb(180, 178, 178) 1px solid;
    height: 82px;
}

.labelIndicadoresCash {
    color: #0079C7;
}

.progress-bara {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #b1cfef !important;
    transition: width 0.6s ease;
}

.theadA {
    color: #fff;
    background-color: #0079C7;
    border-color: #454d55;
}

.labelThead {
    color: #fff !important;
}

.vertical-timeline-element-date {
    font: bold !important;
    color: rgb(0, 0, 0) !important;
}

.labelIndicadorCash {
    color: #46AFE5;
}

.labelIndicadorCashf {
    color: #da0000;
}
.parrafo {
    background-color: #fb00001a;

}

.type-success {
  background-color: #4cfe0257;
}

.type-success-2 {
  background-color: #51bb254a;
}

.type-cancell {
  background-color: #dc354533;
}
.type-cancell-2 {
  background-color: #b105164d;;
}

.table-sm td {
    padding: 0.5rem 0.5rem !important;

}


.theadInvoice{
    color: #fff !important;
    background-color: #033d5b;
    border-color: #454d55;
}

.labeltheadInvoice{
    color: #fff !important;
}

.badgeInvoice{
    color: #fff !important;
    background-color: #033d5b !important

}

.bgTableCash{
    background-color: #22a3e569 !important;
}

.b-complemento {
    border:  1px solid #0a85c4; 
}

.b-invoices {
    border-right: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
}

.b-card-cash {
    border-right: 1px solid rgb(12 108 126) !important;
    border-left: 1px solid rgb(12 108 126) !important;
    border-bottom: 1px solid rgb(12 108 126) !important;
    border-top: 1px solid rgb(12 108 126) !important;
}

.button-cash-Historic {
    padding: 8px 20px; 
    font-size: 14px;
    color: #00e1ff; 
    background-color: transparent; 
    cursor: pointer; 
    border-right: 1px solid #00e1ff !important;
    border-left: 1px solid #00e1ff !important;
    border-bottom: 1px solid #00e1ff!important;
    border-top: 1px solid #00e1ff !important;
  }
  
  .button-cash-Historic:hover {
    background-color: #00e1ff; 
    color: #ffffff; 
    border-right: 1px solid rgb(12 108 126) !important;
    border-left: 1px solid rgb(12 108 126) !important;
    border-bottom: 1px solid rgb(12 108 126) !important;
    border-top: 1px solid rgb(12 108 126) !important;
  }

  .button-cash-success {
    padding: 8px 20px; 
    font-size: 14px;
    color: #28a745; 
    background-color: transparent; 
    cursor: pointer; 
    border-right: 1px solid #28a745 !important;
    border-left: 1px solid #28a745 !important;
    border-bottom: 1px solid #28a745!important;
    border-top: 1px solid #28a745 !important;
  }
  
  .button-cash-success:hover {
    background-color: #28a745;
    color: #ffffff; 
    border-right: 1px solid #28a745 !important;
    border-left: 1px solid #28a745!important;
    border-bottom: 1px solid #28a745!important;
    border-top: 1px solid #28a745 !important;
  }
  .button-cash-danger {
    padding: 8px 20px; 
    font-size: 14px;
    color: #dc3545;
    background-color: transparent; 
    cursor: pointer; 
    border-right: 1px solid #dc3545!important;
    border-left: 1px solid #dc3545 !important;
    border-bottom: 1px solid #dc3545!important;
    border-top: 1px solid #dc3545!important;
  }
  
  .button-cash-danger:hover {
    background-color: #dc3545; 
    color: #ffffff; 
    border-right: 1px solid #dc3545 !important;
    border-left: 1px solid #dc3545 !important;
    border-bottom: 1px solid #dc3545 !important;
    border-top: 1px solid #dc3545!important;
  }

  .button-cash-report {
    padding: 8px 20px; 
    font-size: 14px;
    color: #343a40;
    background-color: transparent; 
    cursor: pointer; 
    border-right: 1px solid #343a40!important;
    border-left: 1px solid #343a40!important;
    border-bottom: 1px solid #343a40!important;
    border-top: 1px solid #343a40!important;
  }
  
  .button-cash-report:hover {
    background-color: #343a40; 
    color: #ffffff; 
    border-right: 1px solid #343a40 !important;
    border-left: 1px solid #343a40!important;
    border-bottom: 1px solid #343a40 !important;
    border-top: 1px solid #343a40!important;
  }

  .button-cash-preview {
    padding: 8px 20px; 
    font-size: 14px;
    color: #0079C7;
    background-color: transparent; 
    cursor: pointer; 
    border-right: 1px solid #0079C7!important;
    border-left: 1px solid #0079C7!important;
    border-bottom: 1px solid #0079C7!important;
    border-top: 1px solid #0079C7!important;
  }
  
  .button-cash-preview:hover {
    background-color: #0079C7; 
    color: #ffffff; 
    border-right: 1px solid #0079C7 !important;
    border-left: 1px solid #0079C7!important;
    border-bottom: 1px solid #0079C7 !important;
    border-top: 1px solid #0079C7!important;
  }

  .button-cash-loading-create {
    background-color: #ccc; 
    color: #ffffff; 
    border-right: 1px solid #ccc !important;
    border-left: 1px solid #ccc!important;
    border-bottom: 1px solid #ccc !important;
    border-top: 1px solid #ccc!important;
    pointer-events: none;
    color: #000000;
    padding: 8px 20px; 
    font-size: 14px;
    cursor: not-allowed; 
  }

  .preview-cash-div {
    border-bottom: 3px dotted #46AFE5;/* Establece una línea punteada de 1 píxel de grosor y color negro */
}


/* Personalización del scroll */
.scroll-container {
  max-height: 500px;
  overflow-y: auto; /* Solo scroll vertical */
  scrollbar-width: thin; /* Para Firefox */
  scrollbar-color: #3498db #ecf0f1; 
}


.scroll-container::-webkit-scrollbar {
  width: 8px; 
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #3498db; 
  border-radius: 4px; 
}

.scroll-container::-webkit-scrollbar-track {
  background-color: #ecf0f1; 
  border-radius: 4px; 
}

.scroll-container::-webkit-scrollbar-track:hover {
  background-color: #d0d0d0; 
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #2980b9; 
}

/* Personalización del scroll */
.scroll-container-B {
  max-height: 400px;
  overflow-y: auto; /* Solo scroll vertical */
  scrollbar-width: thin; /* Para Firefox */
  scrollbar-color: #3498db #ecf0f1; 
}


.scroll-container-B::-webkit-scrollbar {
  width: 8px; 
}

.scroll-container-B::-webkit-scrollbar-thumb {
  background-color: #3498db; 
  border-radius: 4px; 
}

.scroll-container-B::-webkit-scrollbar-track {
  background-color: #ecf0f1; 
  border-radius: 4px; 
}

.scroll-container-B::-webkit-scrollbar-track:hover {
  background-color: #d0d0d0; 
}

.scroll-container-B::-webkit-scrollbar-thumb:hover {
  background-color: #2980b9; 
}


.custom-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.custom-list li {
  font-size: 10px;
  margin-bottom: 5px; /* Espaciado entre los elementos */
}






