.buttonlargee {
  min-width: 25px;
  max-width: 174px;
}

.pagebuttonn {
  min-width: 20px;
  max-width: 174px;
}

.btnAgreement {
  min-width: 15px;
  max-width: 154px;
}

.arcoiriss {
  color: #0079C7
}

.colorbutton {
  color: gold;
}
.colbu {
  color: rgba(250, 239, 239, 0);
}

.periodo {
  color: #7366ff;
}

.status {
  color: lime;
}

.icon-Color{
  color: #0079C7;
}


@media screen and (max-width: 1460px) {
  .imgTableStatus{
    width: 30% !important;
  }
  .labelResumen{
    color: #000000 !important;
    font-size: 14px !important;
  }
  .labelAActivos{
    font-size: 16px !important;
  }
  .labelContract{
    font-size: 22px !important;
  }
  .labelContractNumber{
    font-size: 20px !important;
  }
  .labelPrice{
    font-size: 10px !important;
  }

  .labelColorAmout{
    font-size: 14px !important;
    color: #000000;
  }

  .labelColorAmout2{
    font-size: 14px !important;
    color: #000000;
  }

  .labelColorAmount3{
    font-size: 14px !important;
    color: #f00;
  }

  .labelColorAmount4{
    font-size: 14px !important;
    color: #009624;
  }

  .labelColorAmount5{
    font-size: 14px !important;
    color: #000000;
  }
  .labelgraph{
    font-size: 15px !important;
  } 
  .labelTransaction{
    font-size: 14px !important;
  }
  .imgQuestion{
    width: 40px !important;
  }
  .labelModalQuestion{
    font-size: 12px !important;
  }
  .labelCollapseT{
    font-size: 13px !important;
  }
  .labelInfoCompany{
    font-size: 14px !important;
    font-weight: bold !important;
  }
  .labelInfoCompany2{
    font-size: 12px !important;
  }
  .labelNameBussines{
    font-size: 14px;
  }
  
}

@media screen and (max-width: 1199.5px) {
  .imgTableStatus{
    width: 30% !important;
  }
  .labelResumen{
    color: #000000 !important;
    font-size: 16px !important;
  }
  .labelAActivos{
    font-size: 16px !important;
  }
  .labelColorAmout{
    font-size: 14px !important;
    color: #000000;
  }

  .labelColorAmout2{
    font-size: 14px !important;
    color: #000000;
  }

  .labelColorAmount3{
    font-size: 14px !important;
    color: #f00;
  }

  .labelColorAmount4{
    font-size: 14px !important;
    color: #009624;
  }

  .labelColorAmount5{
    font-size: 14px !important;
    color: #000000;
  }
  .labelgraph{
    font-size: 16px !important;
  } 
  .labelTransaction{
    font-size: 14px !important;
  }
  .imgQuestion{
    width: 40px !important;
  }
  .labelModalQuestion{
    font-size: 12px !important;
  }
  .labelCollapseT{
    font-size: 13px !important;
  }
  .labelInfoCompany{
    font-size: 14px !important;
    font-weight: bold !important;
  }
  .labelInfoCompany2{
    font-size: 12px !important;
  }
  .labelNameBussines{
    font-size: 14px;
  }
}



@media screen and (max-width: 1130px){
  .labelResumen{
    color: #000000 !important;
    font-size: 12px !important;
  }
  .labelAActivos{
    font-size: 10px !important;
  }
  .labelColorAmout{
    font-size: 10px !important;
    color: #000000;
  }

  .labelColorAmout2{
    font-size: 10px !important;
    color: #000000;
  }

  .labelColorAmount3{
    font-size: 10px !important;
    color: #f00;
  }

  .labelColorAmount4{
    font-size: 10px !important;
    color: #009624;
  }

  .labelColorAmount5{
    font-size: 10px !important;
    color: #000000;
  }
  .labelgraph{
    font-size: 12px !important;
  } 
  .labelTransaction{
    font-size: 12px !important;
  }
  .imgQuestion{
    width: 38px !important;
  }
  .labelModalQuestion{
    font-size: 12px !important;
  }
  .labelCollapseT{
    font-size: 12px !important;
  }
  .labelInfoCompany{
    font-size: 12px !important;
    font-weight: bold !important;
  }
  .labelInfoCompany2{
    font-size: 10px !important;
  }
  .labelNameBussines{
    font-size: 14px;
  }
  
}


@media screen and (max-width: 575.99px){
  .imgTableStatus{
    width: 60% !important;
  }
  .labelResumen{
    color: #000000 !important;
    font-size: 12px !important;
  }
  .labelAActivos{
    font-size: 10px !important;
  } 
  .labelgraph{
    font-size: 12px !important;
  } 
  .labelprueb{
    font-size: 10px !important;
  }
  .imgFinancing{
    width: 100% !important;
  }
  .imgFinancing2{
    width: 100% !important;
  }
  .labelContract{
    font-size: 16px !important;
  }

  .labelContractNumber{
    font-size: 14px !important;
  }

  .labelMethodCredit{
    font-size: 10px !important;
  }

  .labelExam{
    font-size: 10px !important;
  }

  .labelDate{
    font-size: 8px !important ;
  }

  .labelPrice{
    font-size: 10px !important;

  }

  .labelTransaction{
    font-size: 12px !important;
  }
 
  .labelColorAmout{
    font-size: 10px !important;
    color: #000000;
  }

  .labelColorAmout2{
    font-size: 10px !important;
    color: #000000;
  }

  .labelColorAmount3{
    font-size: 10px !important;
    color: #f00;
  }

  .labelColorAmount4{
    font-size: 10px !important;
    color: #009624;
  }

  .labelColorAmount5{
    font-size: 10px !important;
    color: #000000;
  }

  .imgQuestion{
    width: 38px !important;
  }

  .labelModalQuestion{
    font-size: 12px !important;
  }

  .modal-content {
    width: 97%;
  }

  .fontSizeIcon {
    font-size: 1em !important;
  }

  .labelCollapseT{
    font-size: 12px !important;
  }

  .labelInfoCompany{
    font-size: 12px !important;
    font-weight: bold !important;
  }

  .labelInfoCompany2{
    font-size: 10px !important;
  }

  .labelNameBussines{
    font-size: 16px;
  }

  .tablepanding{
    border-top: transparent !important;
  }
  
}

.backgroundZeroDownloads{
  background:  #505358 !important;
  color: white;
}
.backgroundZeroDownloads:hover{
  background:  #33383d !important;
  color: white;
}

.backgroundDownloads{
  background:  #204887 !important;
  color: white;
}

.backgroundDownloads:hover{
  background:  #11284e !important;
  color: white;
}
