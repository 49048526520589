.LabelFooter {
  color: #ffffff;
  font-size: 12px !important;
  font-family: 'Montserrat', sans-serif;
}


@media screen and (max-width: 1460px) {
  .LabelFooter {
    color: #ffffff;
    font-size: 12px !important;
    font-family: 'Montserrat', sans-serif;
  }
}

@media screen and (max-width: 1199.5px) {
  .LabelFooter {
    color: #ffffff;
    font-size: 10px !important;
    font-family: 'Montserrat', sans-serif;
  }

}

@media screen and (max-width: 1130px) {
  .LabelFooter {
    color: #ffffff;
    font-size: 10px !important;
    font-family: 'Montserrat', sans-serif;
  }

}


@media screen and (max-width: 575.99px) {
  .LabelFooter {
    color: #ffffff;
    font-size: 10px !important;
    font-family: 'Montserrat', sans-serif;
  }

}


.form-control-sm-w {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.15rem 0.5rem !important;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  background: linear-gradient(181deg, #7ec3e7 0%, #FFFFFF 55.10%) !important;

}