.maximo {
  color: #af44f770;
}

.minimo {
  color: #cb2ddac5;
}

.promedio {
  color: #9e3ff7;
}

.maximoPerfil {
  color: #fa8ea9;
}

.minimoPerfil {
  color: #ff265c;
}

.promedioPerfil {
  color: #f73164;
}

.labelvendido {
  color: #0079C7;

}
