body{
  background-color: #F8F9FA;
}
.recover-password-container{
  *{box-sizing: border-box;}
  .headerRecover{
    background-color: #2B95B4;
    margin: 15px;
    padding: 10px 40px;
    h4{
        font-family: 'Comfortaa';
        font-weight: bold;
        font-size: 2rem;
        text-align: center;
        color: white;
    }
  }

  .recoverContainer{
    margin: 160px auto;
    width: 50%;
    min-width: 550px;
    height: 390px;
    padding: 40px 0;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: white;

    .theme-form {
      width: 90%;
      margin: 0 auto;
      font-family: 'Lato';
        .form-group{
          width: 90%;
          margin: 0 auto;
          position: relative;
          .col-form-label{
            font-size: 1.1rem;
            margin-bottom: 4px;
          }

          input {
            background-color: #f3f3ff;
            transition: all 0.3s ease;
            margin: 0 auto 18px auto;
            border-color: #efefef;
            color: #898989;
    
            &::-webkit-input-placeholder {
              color: #999999;
            }
    
            &:hover,
            &:focus {
              border: 1px solid lighten(#59667a, 35%);
              box-shadow: none !important;
              transition: all 0.3s ease;
            }
          }
        }
        .incorrect-login{
          width: 90%;
          color: #E2231A;
          margin: 0 auto;
          font-size: 0.9rem;
        }
        .mb-0{
          button{
            width: 100%;
            margin-top: 25px;
            background-color: #2B95B4;
            color: white;
            font-family: 'Comfortaa';
            font-weight: bold;
            font-size: 1.2rem;
            padding: 15px 10px;
            box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
          }
        }
        
    }
  }
}
