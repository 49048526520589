.background-container {
    background-image: url('../../../assets/images/price/reportCxc.svg'); 
    background-size: cover;
    background-position: center;
    padding: 20px; 
    height: 160px;
    width: 150px;
    color: #fff; 
  }
  .background-container-Pending {
    background-image: url('../../../assets/images/price/reportCxcPending.svg'); 
    background-size: cover;
    background-position: center;
    padding: 20px; 
    height: 160px;
    width: 150px;
    color: #fff; 
  }

  .background-container-Invoices {
    background-image: url('../../../assets/images/price/reportCxcInvoice.svg'); 
    background-size: cover;
    background-position: center;
    padding: 20px; 
    height: 160px;
    width: 150px;
    color: #fff; 
  }

  .tableSF {
    background-color:  #ff8c971c !important;

  }
  
  