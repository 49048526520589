.icon-color-Company {
    color: #0079C7;
}

.fondo-company {
    color: #0079C7;
}

.fondo-company-data {
    background-color: rgb(244, 243, 243);
}

.icon-rfc {
    background-color: #0079C7;
}

.datos {

    color: rgba(66, 55, 55, 0.935);

}