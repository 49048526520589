.price {
  background-color: #ffffff;
}

.lab {
  color: #fff;
}

.tdd {
  background-color: #7366ff;
}

.kit {
  background-color:  #6495ed,
}

.barra {
  background-color: rgb(238, 238, 238);
}

.rang {
  height: 37px !important;
  margin-top: 5px !important;
}

.staticc {
  position: static;
}

.disableLabel {
  pointer-events: none;
  opacity: 0.5;
}
.bg-listExam {
  background-image: url('../../../assets/images/login/Estudios.svg');
  width: 100%;
  min-height: 70vh;
  background-repeat: no-repeat;
  background-position: center center;
  /* background-attachment: fixed; */
  background-size: cover;
  z-index: 5;
}

.labelCommercialLine{
  color: #0079C7;
}

.tap-top-profile {
  text-align: center;
  cursor: pointer;
  display: none;
  width: 20%;
  height: 40px;
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 5;
  color: #fff;
  background: var(--theme-deafult);
  border: none;
  padding: 7px;
  box-shadow: 0 0 10px var(--theme-deafult);
  opacity: 5;
  transition: all 0.3s ease;
}

.badge-light-primary-Profiles {
  background-color: #46afe524;
}

.badge-light-primary-PriceFinally {
  background-color: #f6a9a042;
  color: rgb(209, 1, 1);
}
.li-Icon {
  color: #007efc;
}

.btn-profile {
  padding: 0.375rem 0.75rem;
}

