.react-kanban-column {
    /* background-color: orange; */
    width: 100%;
  }

.react-kanban-board {
    width: 100%;
}
[data-rbd-droppable-id="board-droppable"]
{
    width: 95%;
}