
.LabelLogin {
  color: #848484;
  font-size: 32px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-align: center;
}

.LabelLogin2 {
  color: #0079C7;
  font-size: 32px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 ;
}
  
.labelMenu1 {
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.hijoMicrosite {
  position: absolute;
  top: 25%;
  left: 0%;
  margin: -25px 0 0 -25px;
}


@media screen and (max-width: 1460px) {
  .imgLoginMicrosite{
    width: 81% !important;
  }
}

@media screen and (max-width: 1199.5px) {
 
}



@media screen and (max-width: 1130px){
 
}


@media screen and (max-width: 575.99px){
  .imgLoginMicrosite{
    width: 95% !important;
  }

  .marginLdiv {
    margin-left: 15px !important;
  }
  
}








