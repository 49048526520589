 .badge-detail-micro {
    font-size: 96%;
    font-weight: 600;
    line-height: 1;
    display: inline-block;
    padding: 0.35em 0.4em;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
    border-radius: 0.21rem;
    background-color: #6a88ff!important;
    border-radius: 5px !important;
    color: #FFF;
    place-content: center;
  }
  


  .badge-detail-Micro-status {
    font-size: 95%;
    font-weight: 600;
    line-height: 1;
    display: inline-block;
    padding: 0.35em 0.4em;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
    border-radius: 0.21rem;
    background-color: #10dfdb !important;
    border-radius: 5px !important;
    color: #FFF;
    place-content: center;
  }

  .badge-detail-Micro-cancel {
    font-size: 95%;
    font-weight: 600;
    line-height: 1;
    display: inline-block;
    padding: 0.35em 0.4em;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
    border-radius: 0.21rem;
    background-color: #f17985 !important;
    border-radius: 5px !important;
    color: #FFF;
    place-content: center;
  }

  .badge-detail-Micro-cancel2 {
    font-size: 95%;
    font-weight: 600;
    line-height: 1;
    display: inline-block;
    padding: 0.35em 0.4em;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
    border-radius: 0.21rem;
    background-color: #6c757dc4 !important;
    border-radius: 5px !important;
    color: #FFF;
    place-content: center;
}


.ribbon-samplings {
  clear: left;
  color: #fff;
  height: 30px;
  left: 0;
  line-height: 30px;
  padding: 0 20px;
  position: absolute;
  top: 12px;
  z-index: 2;
  background: #f73164;
  right: -14px;
}

.input-borde-bajo {
  border: none; 
  border-bottom: 2px solid #007bff; 
  padding: 5px; 
  font-size: 12px; 
  outline: none; 
  width: 260px;
}

.input-borde-bajo:focus {
  border-bottom: 2px solid #007bff; 
  box-shadow: 0 3px 5px 1px rgba(0, 121, 199, 0.2); 
}

.input-save-samplings {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 0 0.5rem;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fff;
  border-color: #fff;
  background-color: #3b5998;
  width: 169px;
  height: 37px;
  font-size: 13px;
}

.input-save-samplings i {
  margin-right: 0.5rem; 
}

.divider {
  display: inline-block;
  width: 1px;
  height: 18px; 
  background-color: #fff; 
  margin: 0 0.5rem; 
}