@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



 .p-inputtext {
    font-family: 'Montserrat', sans-serif;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.2rem 0.75rem !important;
    /* padding: 0.75rem 0.75rem; */
    border: 1px solid #007bff !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 1px;
}

.p-component {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: normal;
}

/* p-multiselect-empty-message */
/* modificar esto el dia de mañana */
    