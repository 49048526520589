.floating-card {
    max-width: 300px;
    position: fixed;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    transition: all 0.3s ease;
    visibility: visible;
    opacity: 1;
    z-index: 10000;
  }