.multiselect-demo .p-multiselect {
    min-width: 15rem;
}

.multiselect-demo .multiselect-custom .p-multiselect-label:not(.p-placeholder):not(.p-multiselect-items-label) {
    padding-top: .25rem;
    padding-bottom: .25rem;
}

.multiselect-demo .multiselect-custom .country-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .5rem;
    background-color: "#0079c7";
    color: var(--primary-color-text);
}

.multiselect-demo .multiselect-custom .country-item-value img.flag {
    width: 100%;
}
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #0079C7 !important;
    background: #0079C7 !important;
}                
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
    color: hsl(0, 0%, 50%) !important;
}
.p-multiselect:not(.p-disabled).p-focus{
    border-color: #0079C7 !important;
}
.p-multiselect:not(.p-disabled):hover{
    border-color: #0079C7 !important;
}
.clase_desabilita{
    pointer-events: none !important;
}
/* .p-component{
    //z-index: 999999 !important; 
} */

.p-multiselect.p-component.p-inputwrapper.p-inputwrapper-filled.basic-multi-select.input-air-primary.form-control-sm {
    max-width: 103% !important;
    z-index: 99999 !important;
}

@media screen and (max-width: 768px) {
    .multiselect-demo .p-multiselect {
        min-width: auto;
        width: 30%;
    }
}