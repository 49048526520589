
.iconResults {
    color:  #0079C7;
}

.fondo {
    background-color: #46AFE5;
}

#mt-10{
  margin-top: -20px !important;
  background-color: #46AFE5 !important;
}
@media screen and (max-width: 1460px) {
   
    .imgGender{
      width: 95% !important;
    }
    .imgQ{
      width: 75% !important;
    }
    
    .Label-input {
        color: #ffffff;
        font-size: 18px;
        text-align: center;
    }
    
    
  }
  
  @media screen and (max-width: 1199.5px) {
    .imgGender{
        width: 95% !important;
      }
      .imgQ{
        width: 75% !important;
      }
   
  }
  
  @media screen and (max-width: 1130px){
    .imgGender{
        width: 95% !important;
      }
      .imgQ{
        width: 75% !important;
      }
      .labelPatient{
        font-size: 11px !important; 
      }
      .pPatient{
        font-size: 10px !important; 
      }
      .Label-input {
        color: #ffffff;
        font-size: 14px;
        text-align: center;
    }
  }
 
  @media screen and (max-width: 575.99px){
    .imgGender{
        width: 95% !important;
      }
      .imgQ{
        width: 75% !important;
      }
      .labelPatient{
        font-size: 11px !important; 
      }
      .pPatient{
        font-size: 10px !important; 
      }
      .Label-input {
        color: #ffffff;
        font-size: 13px;
        text-align: center;
    }
  }