.Timepicker {
  height: 140px !important;
  width: 400px !important;
}

.labeldate {
  height: 40px;
  width: 20;
}


.wide-textarea {
  width: 100% !important; 
  min-width: 300px !important; 
  height: 300px !important;
  border-color: #1f9ad9 !important;
}

