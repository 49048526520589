@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.Card-nim-Component-Header {
    border: 2px solid #AA99FF;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    width: 98%;
    height: 42px;
    background-color: #AA99FF;
}


.Card-nim-Component-Body {
    border: 2px solid #AA99FF;
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
    width: 98%;
    height: 155px;
}

.circulo-nim-Component-Icon {
    width: 60px;
    height: 60px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #AA99FF;
    text-align: center;
    margin-left: 10px !important;
}

.Label-Card-Header {
    color: #ffffff;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

.Label-Card-Header-Time {
    color: #ffffff;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-weight: bold;
}

.Label-nameSection-Body {
    color: #000000;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-weight: bold;
}

.Div-nameExam-Body {
    line-height: 140%;
    color: #000000;
    font-size: 10px;
    font-family: 'Montserrat', sans-serif;
}

.Button-Finish-Body {
    background-color: #FF00AA;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 10px;
    font-weight: bold;
    width: 102%;
    height: 30px;
    transform: scale(var(--escala, 1));
    transition: transform 0.25s;
}

.tamañoinputNim {
    height: 45px !important;
    margin-top: 1px !important;
    right: 40px;
    width: 300px;
    text-align-last: center;
    border-radius: 4px;
    border-radius: 20px;
    border: 3px solid #0079C7;
}

.Div-scaner {
    width: 100%;
}

.Div-scaner ::after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.Modal-Button-Checked {
    background-color: #0079C7;
    font-weight: bold;
    color: #ffffff;
    border: 3px solid #0079C7;
    border-radius: 15px;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    width: 180px;
    height: 40px;
}

.Label-Modal-Checked {
    color: #0079C7;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-weight: bold;
}

.Label-Modal-Checked-Txt {
    color: #000000;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-weight: bold;
}

.icon-Pruebas {
    color: #ffffff;
}

.btn-circle-Departamento.btn-xl {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 18px;
    line-height: 1.33;
    background-color: #3A24A8;
    z-index: 1050;
}



/* .Nueva-Area {
    z-index: 2;
    width: 355px;
    height: 360px;
      
        top: 15px;
        left: 20px;
        border: 1px dashed #0079C7;
        background: #ffffff;
        margin: 0px 50px 0px 50px;
        text-align: center;
} */


.Label-FromCard {
    color: #0079C7;
    font-size: 19px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-weight: 800
}

.Input-From-Section-Card {

    margin-top: 1px !important;
    width: 100%;
    height: 50px;
    text-align-last: center;
    border-radius: 4px;
    border-radius: 15px;
    border: 2px solid #a1a1a1;
}

.Input-FromCard {
    height: 50px !important;
    margin-top: 1px !important;
    width: 98%;
    border: 2px solid #a1a1a1;
    border-radius: 4px;
    border-radius: 15px;
}

.Input-FromCard::placeholder {
    color: #3b3a3b;
    font-size: 1.2em;
    font-family: 'Montserrat', sans-serif;
    padding-left: 20px !important;
    size: 40px;
}

.Button-FromCard {
    background-color: #3A24A8;
    font-weight: bold;
    color: #ffffff;
    border: 3px solid #3A24A8;
    border-radius: 15px;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    width: 70%;
    height: 40px;
}

.cat {
    height: 50px;
    width: 20px;
}

.Input-search-Nim {

    margin-top: 1px !important;
    border: 2px solid #0079C7;
    border-radius: 12px;
    width: 350px;
    height: 35px;
}

.Input-search-Nim::placeholder {
    color: #0079C7;
    font-size: 1.4em;
    font-family: 'Montserrat', sans-serif;
    padding-left: 20px !important;
    size: 40px;
    font-weight: bold;
}

.circle-cat {
    float: none;
    content: '';
    position: relative;
    float: left;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #FEFEFE;
    margin: 20px 30px;
    z-index: -2;
    border: solid 1px #B7B7B7;
    left: 50%;
    box-sizing: content-box;

}

.label-Añadir {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: center;
}

.img-Area {
    color: #0079C7;
    border-left: 3px solid;
    border-top-left-radius: 10px;
    /* border-bottom-left-radius: 10px; */


}

.iconCheck {
    position: relative;
}

.fa-search {
    position: absolute;
    padding: 5px;
    right: 1px;
    color: #0079C7;
}

.icon-regresar {
    color: #FF00AA;

}

.borde-sesion {
    border: solid 3px #0079C7;
    border-top-left-radius: 13px;
}

.tab1 {
    background: "white";
    display: "flex";
    border: "#3a25a8 3px solid",
}


.Label-tab {
    color: #ffffff;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;

}

.row-departamento {
    width: 1060px;
    height: 58px;
    border: 3px solid #0079C7;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border-top-right-radius: 16px;
}

.pruebaTraking {
    border: 3px solid #0079C7;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border-top-right-radius: 16px;

}




.Label-tab-1 {
    color: #0079C7;
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-weight: bold;
}

.r-label-active-Seguimiento {
    color: #ffffff;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: center;
}

.label-div-menu {
    color: #ffffff;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.time-Seguimiento {
    color: #ffffff;
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: center;
}

.box-reloj-Seguimiento {
    width: 280px;
    height: 56px;
    border: 3px solid #0079C7;
    background: #0079C7;
    border-radius: 14px;
}

.ocultar-input {

    opacity: 0;
}

/* gradillas */


.Card-gradilla-Component-Header {
    border: 2px solid #AA99FF;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    width: 1000px;
    height: 60px;
    background-color: #AA99FF;
}


.Card-gradilla-Component-Body {
    border: 2px solid #AA99FF;
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
    width: 1000px;
    height: 250px;
}

.line-gradilla {
    border: 3px solid #AA99FF;
    border-radius: 15px;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    width: 150px;
    height: 40px;
}

.button-Gradilla {
    background-color: #FF00AA;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 10px;
    font-weight: bold;
    width: 160px;
    height: 45px;
}

.tab-Gradilla {
    background-color: #3A24A8;

}

.modalButton-gradilla {
    background-color: #FF00AA;
    font-weight: bold;
    color: #ffffff;
    border: 3px solid #FF00AA;
    border-radius: 15px;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    width: 220px;
    height: 45px;
}

.label-Modal-gradilla {
    color: #000000;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

.label-Modal-gradilla-nim {
    color: #000000;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

.label-Title-gradilla {
    color: #ffffff;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    /* text-align: center; */
}

.modalButtonChangeGradilla {
    background-color: #FF00AA;
    font-weight: bold;
    color: #ffffff;
    border: 3px solid #FF00AA;
    border-radius: 15px;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    width: 190px;
    height: 40px;
}

.modalButtonChangeGradilla2 {
    background-color: #FF00AA;
    font-weight: bold;
    color: #ffffff;
    border: 3px solid #FF00AA;
    border-radius: 15px;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    width: 190px;
    height: 40px;
}


.username-Active-traking {
    width: 90%;
    height: 50px;
    background: #46afe5;
    border-radius: 25px;
    box-shadow: 0 0 10px #46afe5;
    transition: all 0.3s ease;
}


.Nueva-Area {
    width: 20%;
    /* height: 85%; */
    background: #ffffff;
    border: 3px solid #0079C7;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    float: right;
    position: absolute;
    z-index: 1;
    margin-left: 80%;
}



.buttonTraking {
    background-color: #0079C7;
    font-weight: bold;
    color: #ffffff;
    border: 3px solid #0079C7;
    border-radius: 18px;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    width: 25%;
    height: 50px;
}

.buttonRefused {
    background-color: #0079C7;
    font-weight: bold;
    color: #ffffff;
    border: 3px solid #0079C7;
    border-radius: 18px;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    width: 60%;
    height: 40px;
}

.imgbarTraking {
    width: 29%;
}


.TrakingmodalHeader {
    background-color: #0079C7;
    height: 55px;
}



.circulo-nim-Component-Icon-porcentaje {
    /* height: 60px; */
    height: 100%;
    border-radius: 50%;
    background: #AA99FF;
    text-align: center;
    /* margin-left: 10px !important; */
}

.circle-studyTracking {
    background: #AA99FF;
    border-radius: 50%;
    width: 78px;
    height: 78px;
    margin-left: 8px !important;
}

.statusIconTracking {
    color: #46AFE5;
}

.imagen {
    transform: scale(var(--escala, 1));
    transition: transform 0.25s;
}

.imagen:hover {
    --escala: 1.2;
    cursor: pointer;
}

.Button-Finish-Body:hover {
    --escala: 1.2;
    cursor: pointer;
}


.tap-top-code-Tracking {
    text-align: center;
    cursor: pointer;
    display: none;
    width: 10%;
    height: 40px;
    border-radius: 100%;
    position: fixed;
    bottom: 22px;
    right: 10px;
    z-index: 5;
    color: #fff;
    border: none;
    padding: 7px;
    box-shadow: 0 0 10px
}



.tap-top-menu-Tracking {
    text-align: center;
    cursor: pointer;
    display: none;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    position: fixed;
    bottom: 10px;
    left: 30px;
    z-index: 5;
    color: #fff;
    background-color: #46AFE5;
    border: none;
    padding: 7px;
    box-shadow: 0 0 10px var(--theme-deafult);
    opacity: 0.5;
    transition: all 0.3s ease;
}

.colorModalIDmuestraTracking {
    background-color: #0079C7;
    height: 55px;
}


.tap-top-CardToma {
    text-align: center;
    cursor: pointer;
    /* display: none; */
    width: 160px;
    height: 35px;
    /* position: fixed; */
    bottom: 30px;
    /* z-index: 5; */
    color: #fff;
    background: #46afe56b;
    /* background: var(--theme-deafult); */
    border-color: #fff;
    padding: 7px;
    box-shadow: 0 0 10px #fff;
    opacity: 1;
    transition: all .3s ease;
}

.modal-label-modal {
    font-size: 11px;
}