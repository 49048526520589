@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.LabelLogin {
    color: #848484;
    font-size: 28px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .LabelLogin2 {
    color: #0079C7;
    font-size: 28px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: center;
  }


  