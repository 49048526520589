

.table-wrapper-mic {
  width: 100%;
  height: 250px; /* Altura de ejemplo */
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #002940 rgba(0, 0, 0, 0);
}

.table-wrapper-mic table thead {
  position: -webkit-sticky; /* Safari... */
  position: sticky;
  top: 0;
  left: 0;
}

.table-wrapper-mic-quoter {
  width: 100%;
  height: 350px; /* Altura de ejemplo */
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #46afe5 rgba(0, 0, 0, 0);
}

.table-wrapper-mic-mic-quoter table thead {
  position: -webkit-sticky; /* Safari... */
  position: sticky;
  top: 0;
  left: 0;
}


.table-wrapper-mic-samplings {
  width: 100%;
  height: 350px; /* Altura de ejemplo */
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #c7d0ff rgba(0, 0, 0, 0);
}

.table-wrapper-mic-samplings table thead {
  position: -webkit-sticky; /* Safari... */
  position: sticky;
  top: 0;
  left: 0;
}

.color-table{
  border-bottom: 2px solid #007bff; /* Borde azul solo en la parte inferior */
    padding: 8px; /* Espaciado interno */
}

.table-tables {
  border-bottom: 2px solid #007bff; /* Borde azul solo en la parte inferior */
  padding: 8px; /* Espaciado interno */
  vertical-align: middle; /* Centrar verticalmente */
  height: 50px; /* Altura fija de las filas */
}















