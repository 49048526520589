.pruebasW {
    min-width: 25px;
    max-width: 174px;
}

.iconWorkSheetsDay {
    color: #0079C7;
}


.Label-input-capture {
    color: #0079C7;
    font-size: 16px;
    text-align: center;
}

.backCard {
    background: transparent;
}

.rdtPicker {
    min-width: 180px;
    z-index: 99999999 !important;
}

.p-multiselect {
    display: inline-flex;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    height: 40px !important;
    width: 323px !important;
}


.p-multiselect-label {
    display: block;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    color: #000000 !important;
    text-overflow: ellipsis;
}


.p-multiselect-label-container {
    overflow: hidden;
    flex: 1 1 auto;
    cursor: pointer;
    font-size: 14px !important;
}




.p-multiselect .p-multiselect-label {
    padding: 0.2rem 0.5rem !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #fff;
    background: #fff;
}

/* aqui mover si hay algo malo */

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.2s;
    color: #46AFE5;
    font-size: 14px;
}


.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #46AFE5 !important;
    background: #f5f5f5 !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: 0.75rem 1.25rem;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
    font-size: 13px !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #ffffff !important;
}


.p-checkbox .p-checkbox-box {
    border: 2px solid #46AFE5 !important;
    background: #ffffff;
    width: 22px;
    height: 22px;
    color: #495057;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.iconEdit {
    color: #fff;
}

.widget-1 .widget-content {
    align-items: center;
    display: flex;
    gap: 15px;
}

.widget-1 .widget-round.warning {
    border-color: #ffaa05;
}

.widget-1 .widget-round {
    border-radius: 100%;
    border-style: solid;
    border-width: 1px;
    display: inline-block;
    position: relative;
}

.widget-1 .widget-round .bg-round {
    align-items: center;
    border-radius: 100%;
    box-shadow: 1px 2px 21px -2px rgb(214 214 227 / 83%);
    display: flex;
    height: 56px;
    justify-content: center;
    margin: 6px;
    position: relative;
    width: 56px;
    z-index: 1;
}

.gatito:hover {
    box-shadow: 0 0 40px rgb(8 21 66 / 5%);
}

.butto-full-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    height: 56px;
    width: 56px;
    background: rgba(0, 0, 0, .4);
    border-radius: 50%;
    border: none;
    overflow: hidden;
    color: transparent;
    white-space: nowrap;
    color: #fff;

}

.btnTrazabilidad {
    display: inline-block;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;
    border-radius: 0.25rem;
    background-color: #AA99FF;
    border: aliceblue;
    width: 50%;
}

.labelIndications {
    color: #0079c7;
}

.texto-vertical-2 {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}