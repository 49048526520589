.label-Concentimiento-Toma1 {
    color: #000000;
    font-size: 26px !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: center;
}

.label-Question-Toma1 {
    color: #000000;
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
}

.label-BoxQuestion-Toma1 {
    color: #000000;
    font-size: 27px;
    font-family: 'Montserrat', sans-serif;
}


.label-Carta-Toma1 {
    color: #000000;
    font-size: 20px !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: center;
}

.label-Carta-Informado {
    color: #000000;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}


.label-Encuesta-Toma1 {
    color: #000000;
    font-size: 25px !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: center;
}


.label-Question-Toma1 {
    color: #000000;
    font-size: 28px;
    font-family: 'Montserrat', sans-serif;
}


.profile-box {
    background: linear-gradient(103.75deg, #b2e5ff -13.9%, #7366ff 79.68%);
    background: linear-gradient(103.75deg, #b0def4 -13.9%, var(--theme-deafult) 79.68%);
    color: #fff;
}



.cartoon {
    -webkit-animation: bounce-effect 5s ease-in infinite;
    animation: bounce-effect 5s ease-in infinite;
    bottom: -15px;
    position: absolute;
    right: 20px;
}

.collapsible {
    background-color: #53d1f7;
    color: #ffffff;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
}

/* .active,
.collapsible:hover {
    background-color: #8ca5d1;
} */

.content {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    background-color: #94d4e7;
}


.accordion {
    /* //background-color: #fff; */
    width: 90%;
    /* padding: 1.; */
    /* margin: 5px auto; */
    max-width: 500px;
    border-radius: 6px;
    border-left: 3px solid #31a7f5;
    box-shadow: 0 0.5rem 1rem rgb(184, 184, 184);

    /* box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15); */
}

.accordion__link {
    display: inline-block;
    color: #54bcf4;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.2rem;

}

.accordion__item {
    display: grid;
    grid-template-rows: var(--rows, 0fr);
    transition: .3s grid-template-rows;
}

.inputt {
    display: none;
}

.ppp {
    overflow: hidden;
    color: #5acef8;
    font-size: 12px;
}


.accordion:has(:checked) {
    --rows: 1fr;
}



.alert-ine-toma {
    background-color: rgb(255, 255, 255);
    padding: 1px 20px 13px 65px;
    /* border-color: #dc3545 !important; */
    color: #2b2b2b !important;
    border: 1px solid #dc3545;
    /* background: #0079C7; */
}

.divIne {
    padding: 18px 20px;
    display: inline-block;
    background-color: #dc3545 !important;
    color: #ffffff;
    border-radius: 3px 0 0 3px;
    position: absolute;
    left: 0;
    top: 0;
    height: 10%;
    z-index: 1 !important;
}

.cardSampleD {
    background: #f0f8fd !important;
    height: auto !important;
}

.question {
    background: #ffffff !important;
}