.dropdown-item:hover {
    color: #0a54c1 !important;
    text-decoration: none;
    background-color: #e3f6fe !important;
}

.dropdown-item {
    color: #848484 !important
}


.btnTrazabilidad {
    display: inline-block;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;
    border-radius: 0.25rem;
    background-color: rgb(10 89 106);
    color: #fff;
}

.btnInvoice {
    display: inline-block;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #fff;
    background-color: #00609e;
    border: aliceblue;
    /* width: 50%; */
}

.btnInvoiceCancell {
    display: inline-block;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #fff;
    background-color: #00000091;
    border: aliceblue;
    width: 50%;
    opacity: 0.65;
    cursor: none !important;
}

.colorTable {
    background-color: #e91e6317 !important;
}

/* .colorTable {
    background-color: #b30a4217;
} */

.labelCancell {
    color: #ff0000;
}

.general-Public {
    display: inline-block;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #ffffff;
    background-color: #d91e1e91;
    border: aliceblue;
    width: 50%;
    opacity: 0.65;
    cursor: none !important;
}

.general-Public-dashboard {
    display: inline-block;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #ffffff;
    background-color: #d91e1e91;
    border: aliceblue;
    width: 10%;
    opacity: 0.65;
    cursor: none !important;
}

.btnInvoiceCancell-dashboard {
    display: inline-block;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #fff;
    background-color: #00000091;
    border: aliceblue;
    width: 10%;
    opacity: 0.65;
    cursor: none !important;
}

.tap-top-invoice-general {
    text-align: center;
    cursor: pointer;
    display: none;
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 10px;
    z-index: 5;
    color: #fff;
    background-color: #d91e1e91;
    border: none;
    padding: 7px;
    box-shadow: 0 0 10px var(--theme-deafult);
    opacity: 0.5;
    transition: all 0.3s ease;
    top: 85%;
}
.tap-top-invoice-patient-company {
    text-align: center;
    cursor: pointer;
    display: none;
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 10px;
    z-index: 5;
    color: #fff;
    background-color: #00000091;
    border: none;
    padding: 7px;
    box-shadow: 0 0 10px var(--theme-deafult);
    opacity: 0.5;
    transition: all 0.3s ease;
    top: 85%;
}
/* .tap-top-invoice-general {
    text-align: center;
    cursor: pointer;
    display: none;
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 10px;
    z-index: 5;
    color: #fff;
    background-color: #d91e1e91;
    border: none;
    padding: 7px;
    box-shadow: 0 0 10px var(--theme-deafult);
    opacity: 0.5;
    transition: all 0.3s ease;
    top: 85%;
} */


.incompleteBadge{
    background-color: #FF8F00;
}

/* dropdown-item:focus */