.deni-react-treeview-container.classic2 {
    border-color: #ffffff;
}


.deni-react-treeview-container5 {
    font-family: tahoma, arial;
    font-size: 12px;
    padding: 0px;
    overflow-x: auto;
    overflow-y: auto;
    border: solid 1px;
    border-color: transparent !important;
    width: 400px;
    height: 350px;
    background-color: transparent !important;
}