/**=====================
     54. Tour  CSS Start
==========================**/

// .introjs-helperLayer {
//   background-color: rgba(255, 255, 255, 0.9);
//   border: 1px solid rgba(0, 0, 0, 0.5);
//   box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
// }
// .introjs-tooltipReferenceLayer {
//   background-color: transparent;
// }
// .introjs-helperNumberLayer {
//   color: $white;
//   text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
//   background: $danger-color;
//   background: linear-gradient(to bottom, $tour-color 0%, $tour-color 100%);
//   border: 3px solid $white;
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
// }
// .introjs-arrow {
//   border: 12px solid $white;
//   &.top {
//     border-top-color: transparent;
//     border-right-color: transparent;
//     border-bottom-color: $tour-color;
//     border-left-color: transparent;
//   }
//   &.top-right {
//     border-top-color: transparent;
//     border-right-color: transparent;
//     border-bottom-color: $tour-color;
//     border-left-color: transparent;
//   }
//   &.top-middle {
//     border-top-color: transparent;
//     border-right-color: transparent;
//     border-bottom-color: $tour-color;
//     border-left-color: transparent;
//   }
//   &.right {
//     border-top-color: transparent;
//     border-right-color: transparent;
//     border-bottom-color: transparent;
//     border-left-color: $tour-color;
//   }
//   &.right-bottom {
//     border-top-color: transparent;
//     border-right-color: transparent;
//     border-bottom-color: transparent;
//     border-left-color: $tour-color;
//   }
//   &.bottom {
//     border-top-color: $tour-color;
//     border-right-color: transparent;
//     border-bottom-color: transparent;
//     border-left-color: transparent;
//   }
//   &.left {
//     border-top-color: transparent;
//     border-right-color: $tour-color;
//     border-bottom-color: transparent;
//     border-left-color: transparent;
//   }
//   &.left-bottom {
//     border-top-color: transparent;
//     border-right-color: $tour-color;
//     border-bottom-color: transparent;
//     border-left-color: transparent;
//   }
// }
// .introjs-tooltip {
//   background-color: $tour-color;
//   box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
//   color: $white;
//   border-color: $tour-color;
// }
// .introjs-button {
//   border: 1px solid $light-semi-gray;
//   text-shadow: 1px 1px 0 $white;
//   color: $theme-body-font-color !important;
//   background-color: $white;
//   background-image: linear-gradient($white, $white);
//   &:hover {
//     border-color: $light-semi-gray;
//   }
//   &:focus, &:active {
//     background-image: linear-gradient($light-gray, $light-semi-gray);
//   }
// }
// .introjs-skipbutton {
//   color: $tour-color;
// }
// .introjs-prevbutton {
//   &.introjs-fullbutton {
//     border: 1px solid $light-semi-gray;
//   }
// }
// .introjs-disabled {
//   color: $tour-color;
//   border-color: $light-semi-gray;
//   &:hover, &:focus {
//     color: $tour-color;
//     border-color: $light-semi-gray;
//   }
// }
// .introjs-bullets {
//   ul {
//     li {
//       a {
//         background: $light-gray;
//         &:hover {
//           background: $white;
//         }
//         &.active {
//           background: $white;
//         }
//       }
//     }
//   }
// }
// .introjs-progress {
//   background-color: $light-color;
// }
// .introjs-progressbar {
//   background-color: $tour-color;
// }
// .introjs-hint:hover > .introjs-hint-pulse {
//   border: 5px solid rgba(60, 60, 60, 0.57);
// }
// .introjs-hint-pulse {
//   border: 5px solid rgba(60, 60, 60, 0.27);
//   background-color: rgba(136, 136, 136, 0.24);
// }
// .introjs-hint-dot {
//   border: 10px solid rgba(146, 146, 146, 0.36);
// }

.reactour__helper {
  span[data-tour-elem="badge"] {
    background: $primary-color !important;
    height: unset !important;
  }
  .reactour__dot,
  button {
    &:focus {
      outline: none;
    }
  }
  &:focus {
    outline: none;
  }
}
.reactour__dot--is-active {
  color: $primary-color !important;
  background: $primary-color !important;
}
.gtkSUb {
  right: 18px !important;
}
/**=====================
    54. Tour  CSS Ends
==========================**/