
.CardTitle2{
  background: linear-gradient(270deg, #0079C7 0%, #FFFFFF 85.94%)
}

.LabelLoginH {
  color: #848484;
  font-size: 28px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-align: center;
}

.LabelLogin2H {
  color: #0079C7;
  font-size: 28px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  /* font-weight: lighter; */
}

.CardTitle3{
  background: linear-gradient(270deg, #46AFE5 25.30%, #0079C7 85.94%)
}


/* Degradado Footer:
46AFE5 a D2ECF9
Degradado Header:
FFFFFF (No empieza en 0, empieza como en 25-30%) a 0079C7 */

