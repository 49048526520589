

@media screen and (max-width: 1460px) {
     
  .labelInfoAnalyte{
    font-size: 13px !important;
  }


}

@media screen and (max-width: 1199.5px) {
     
  .labelInfoAnalyte{
    font-size: 13px !important;
  }
  


}

@media screen and (max-width: 1130px){
     
  .labelInfoAnalyte{
    font-size: 11px !important;
  }

 

}



@media screen and (max-width: 575.99px){
   
  .labelInfoAnalyte{
    font-size: 11px !important;
  }


  
}




