.AgreementPicker {
  height: 30px !important;
  margin-top: 18px !important;
}

.AgreementDiscount {
  height: 30px !important;
  margin-top: 0px !important;
}

.AgreementLabel {
  height: 30px !important;
  margin-top: 16px !important;
}

.dClientes {
  color: #0079C7;
}

.tittle-color {
  color: #46AFE5;
}

.ObservationA {
  height: 80px !important;
  width: 400px !important;
}

.cancellExam {
  color: #dc3545;
}

.cancellExam2 {
  background-color: #dc3545;
}




.button-csv {
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  height: 30px;
  width: 90px;
}

.button1 {
  background-color: transparent;
  color: #005a94;
  border: solid #005a94;
}

.button1:hover {
  background-color: #005a94;
  color: white;
}

.button2 {
  border-color: #dc3545;
  color: #dc3545;
  background-color: transparent;
}

.button2:hover {
  background-color: #dc3545;
  color: white;
}

.b-r-csv {
  border-right: 1px solid #ffffff !important;
}