.buttonlarge {
  min-width: 25px;
  max-width: 174px;
}

.pagebutton {
  min-width: 20px;
  max-width: 174px;
}

.btnAgreement {
  min-width: 15px;
  max-width: 154px;
}

.arcoiriss {
  color: #0079C7;
}

.colorbutton {
  color: gold;
}
.colbu {
  color: rgba(250, 239, 239, 0);
}

.periodo {
  color: #7366ff;
}

.status {
  color: lime;
}
.reac {
 color: rgba(89, 210, 254, 0.85)
}

.labelNewCompany{
  color: #0DC356;
}

.labelDangerCompany{
  color:  #D8263E;
}

.labelReactivateCompany{
  color: #DC9019;
   
}

.labelAgreementM{
  color: #595959;
}




