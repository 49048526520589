.accordion {
    background-color: #fff;
    width: 90%;
    /* padding: 1.; */
    /* margin: 5px auto; */
    max-width: 500px;
    border-radius: 6px;
    border-left: 3px solid #0079C7;
    box-shadow: 0 0.5rem 1rem rgb(184, 184, 184);

    /* box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15); */
}

.accordion__link {
    display: inline-block;
    color: #853bf4;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.2rem;

}

.accordion__item {
    display: grid;
    grid-template-rows: var(--rows, 0fr);
    transition: .3s grid-template-rows;
}

.inputt {
    display: none;
}

/* .pp {
    overflow: hidden;
    color: #853bf4;
} */


.accordion:has(:checked) {
    --rows: 1fr;
}