/**=====================
    25. Authentication CSS Start
==========================**/
body{
  background-color: #F8F9FA;
}
.login-main-container{
  *{box-sizing: border-box;}
  .col-xl-4{
    .left-container{
      height: 100%;
      padding: 28px;
      .title{
        margin:0px;
        margin-block: 5%;
        font-size: 1rem;
        img{
          width: 8%;
        }
      }
      .subtitle{
        font-size:2.3rem,
      }
      .image-left-container{
        margin-top: 10%;
        display: flex;
        justify-content: center;
        img{
          margin: 0 auto;
        }
      }
    }
  }
  .login-card {
    font-family:  $font-rubik, $font-serif;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background-position: center;
    padding: 30px 0;
  
  
    .login-main {
      width: 50%;
      min-width: 550px;
      padding: 40px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      margin: 0 auto;
      background-color: $white;
  
      h4 {
        font-size: 1.7rem;
        letter-spacing: 1px;
        text-align: center;
      }
  
      button{
        width: 100%;
        margin-top: 25px;
        background-color: #46AFE5;//gato
        color: white;
        font-family: $font-rubik, $font-serif;
        font-weight: bold;
        font-size: 1.2rem;
        padding: 17px 10px;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
      }
  
      .theme-form {  
        .incorrect-login{
          width: 90%;
          color: #E2231A;
          margin: 0 auto;
          font-size: 0.8rem;
        }
        .form-group{
          width: 90%;
          margin: 0 auto 10px auto;
          position: relative;
          .col-form-label{
            font-size: 1.1rem;
            margin-bottom: 4px;
          }
          
        }

        .password-forgotten{
          margin: 20px auto;
          text-align: center;
          font-family: 'Lato';
          color: #46AFE5;//gato
          font-size: 1rem;
          &:hover{
            cursor: pointer;
          }
        }
  
        input {
          background-color: #f3f3ff;
          transition: all 0.3s ease;
          margin-bottom: 23px;
  
          &::-webkit-input-placeholder {
            color: $light-text;
          }
  
          &:hover,
          &:focus {
            border: 1px solid lighten($semi-dark, 35%);
            box-shadow: none !important;
            transition: all 0.3s ease;
          }
        }
  
        p {
          margin-bottom: 25px;
          font-size: 14px;
          color: $dark-gray;
        }
  
  
        .link {
          position: absolute;
          top: 10px;
          right: 0;
        }
      }
      &.login-tab {
        .nav-tabs {
          border-bottom: none;
          position: absolute;
          background-color: $white;
          box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
          left: -70px;
          top: 0;
          margin-bottom: 0;
          .nav-item {
            .nav-link {
              position: relative;
              img {
                filter: grayscale(100%);
                width: 20px;
                height: 20px;
                transition: 0.5s;
              }
              &:after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-color: transparent;
                border-style: solid;
                top: 12px;
                border-width: 5px 0 5px 5px;
                border-left-color: lighten($primary-color, 25%);
                opacity: 0;
                transform: scale(0);
                color: #fff;
                left: 14px;
                z-index: 2;
                transition: all 0.3s ease;
              }
              span {
                position: absolute;
                right: 0;
                width: max-content;
                text-align: right;
                padding: 7px 10px;
                display: block;
                top: 0;
                border-radius: 5px;
                transform: scale(0);
                transition: all 0.3s ease;
                opacity: 0;
                color: var(--theme-deafult);
                background-color: lighten($primary-color, 25%);
                box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
              }
          
              &:hover {
                &:after {
                  opacity: 1;
                  transform: scale(1);
                  transition: all 0.3s ease;
                }
          
                span {
                  opacity: 1;
                  right: 56px;
                  transform: scale(1);
                  transition: all 0.3s ease;
                }
              }
              &.active {
                img {
                  filter: initial;
                  transition: 0.5s;
                }
              }
            }
          }
        }
        .tab-content {
          height: 458px;
        }
  
        
        .border-tab {
          border-radius: 0;
          &.nav-tabs {
            .nav-item {
              .nav-link {
                border-bottom: none;
                border-right: 2px solid transparent;
                border-top-right-radius: 0;
                &.active, &.show, &:focus {
                  border-right: 2px solid var(--theme-deafult);
                  border-bottom: none;
                }
              }
            }
          }
        }
        .auth-content {
          text-align: center;
          p{
            margin: 0 auto 15px auto;
            width: 90%;
            letter-spacing: 0.1px;
            font-family: 'Lato';
            font-size: 1.1rem;
            line-height: 1.2;
          }
  
          .back-login{
            margin: 20px auto;
            color: #46AFE5;//gato
            font-size: 1rem;
            &:hover{
              cursor: pointer;
            }
          }
          .incorrect-mail{
            text-align: start;
            width: 90%;
            color: #E2231A;
            margin: 0 auto 5px auto;
            font-size: 0.9rem;
          }
  
          input {
            width: 90%;
            background-color: #f3f3ff;
            transition: all 0.3s ease;
            margin: 0 auto 18px auto;
            border-color: #efefef;
            color: #898989;
    
            &::-webkit-input-placeholder {
              color: $light-text;
            }
    
            &:hover,
            &:focus {
              border: 1px solid lighten($semi-dark, 35%);
              box-shadow: none !important;
              transition: all 0.3s ease;
            }
          }
  
          button{
            width: 90%;
            margin-top: 10px;
          }
          
        }
      }
    }
  }
}

.show-hide {
  position: absolute;
  margin-top: 15px;
  right: 20px;
  transform: translateY(-50%);

  span {
    cursor: pointer;
    font-size: 13px;
    color: var(--theme-deafult);

    &.show {
      &:before {
        font-family: FontAwesome;
        color: #46AFE5;//gato
        content: "\f070 ";
      }
    }

    &:before {
      font-family: FontAwesome;
      color: #46AFE5;//gato
      content: "\f06e";
    }
  }
}

.modal-login{

  .modal-dialog{
    max-width: 60%;
    min-width: 590px;
  }
  .modal-content{
    font-family: 'Comfortaa';
    background-color: #ffffff;
    border: none;
    padding: 20px;
    height: 500px;
    .cancel-icon{
      width: 1.8%;
      margin: 0 0 0 auto;
      &:hover{
        cursor: pointer;
      }
    }
  
    .modal-header{
      border: none;
      .modal-title{
        text-align: center;
        font-weight: bold;
        margin: 0 auto;
        width: auto;
        font-size: 1.3rem;
        .subtitle{
          font-family: 'Lato';
          display: inline;
          font-weight: lighter;
          font-size: 1.9rem;
        }
      }
    }
  
    .modal-body{
      width: 100%;
      text-align: center;
      font-family: 'Lato';
  
      p{
        margin: 0 auto;
        width: 80%;
        font-size: 1rem;
      }
      .row{
        margin: 20px auto;
        height: 75px;
        justify-content: center;
        .col{
          padding: 0;
          margin-inline: 8px;
          flex-grow: 0;
          .text-center{
            background-color: #EDEEEF;
            height: 100%;
            width: 70px;
          }
        }
      }
    }
  
    .modal-footer{
      border: none;
  
      button{
        margin: 0 auto;
        color: white;
        background-color: #46AFE5;//gato
        font-weight: bold;
        font-size: 1.2rem;
        padding: 12px 35px;
        box-shadow: 0 5px 7px rgba(0, 0, 0, 0.3);
      }
    }
  
    .request-new-code{
      text-align: center;
      font-family: 'Lato';
      color: #46AFE5;//garto
      font-size: 0.9rem;
      margin-block: 10px;
      &:hover{
        cursor: pointer;
      }
    }
  
    .incorrect-code{
      font-family: 'Lato';
      color: #E2231A;
      margin: 0 auto;
      font-size: 0.9rem;
    }
  }
}

/**login toma **/
.login-main-container-toma{
  *{box-sizing: border-box;}
  .col-xl-4{
    .left-container-toma{
      height: 100%;
      padding: 28px;
      .title{
        margin:0px;
        margin-block: 5%;
        font-size: 1rem;
        img{
          width: 8%;
        }
      }
      .subtitle{
        font-size:2.3rem,
      }
      .image-left-container-toma{
        // margin-top: 10%;
        //display: flex;
        //justify-content: center;
        height: 633px;
        width: 393px;
        left: 322px;
        top: 64px;
        
        img{
          margin: 0 auto;
        }
      }
    }
  }
  .login-card-toma{
    font-family:  $font-rubik, $font-serif; 
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background-position: center;
    padding: 30px 0;
  
  
    .login-main-toma {
      // width: 50%;   ---->
      min-width: 10px;
      
      // background-attachment: fixed;
      padding: 50px;
      border-radius: 5px;
     // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      margin: 0 auto;
     // background-color: $white;
  
      h4 {
        font-size: 1.7rem;
        letter-spacing: 1px;
        text-align: center;
      }
  
      button{
        width: 120px;
         height: 40px;
        margin-top: 25px;
        // background-color: #5eb0e5;
        color: white;
        font-family: $font-rubik, $font-serif;
        font-weight: bold;
        font-size: 1rem;
        //padding: 17px 10px;
        //box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
        background: transparent;
      }
  
      .theme-form {  
        .incorrect-login{
          width: 90%;
          color: #E2231A;
          margin: 0 auto;
          font-size: 0.8rem;
        }
        .form-group{
          //width: 90%;
          //margin: 0 auto 10px auto;
          position: relative;
          .col-form-label{
            font-size: 1.1rem;
            margin-bottom: 4px;
          }
          
        }

        .password-forgotten-toma{
          margin: 20px auto;
          text-align: center;
          font-family: 'Lato';
          color: #46AFE5;//gato
          font-size: 1rem;
          &:hover{
            cursor: pointer;
          }
        }
  
        input {
          background-color: #f3f3ff;
          transition: all 0.3s ease;
          margin-bottom: 23px;
  
          &::-webkit-input-placeholder {
            color: $light-text;
          }
  
          &:hover,
          &:focus {
            border: 1px solid lighten($semi-dark, 35%);
            box-shadow: none !important;
            transition: all 0.3s ease;
          }
        }
  
        p {
          margin-bottom: 25px;
          font-size: 14px;
          color: $dark-gray;
        }
  
  
        .link {
          position: absolute;
          top: 10px;
          right: 0;
        }
      }
      &.login-tab-toma {
        .nav-tabs-toma {
          border-bottom: none;
          position: absolute;
         // background-color: $white;
          box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
         // left: -70px;
          top: 0;
          margin-bottom: 0;
          .nav-item {
            .nav-link {
              position: relative;
              img {
                filter: grayscale(100%);
                width: 20px;
                height: 20px;
                transition: 0.5s;
              }
              &:after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-color: transparent;
                border-style: solid;
                top: 12px;
                border-width: 5px 0 5px 5px;
                border-left-color: lighten($primary-color, 25%);
                opacity: 0;
                transform: scale(0);
                color: #fff;
                left: 14px;
                z-index: 2;
                transition: all 0.3s ease;
              }
              span {
                position: absolute;
                right: 0;
                width: max-content;
                text-align: right;
                padding: 7px 10px;
                display: block;
                top: 0;
                border-radius: 5px;
                transform: scale(0);
                transition: all 0.3s ease;
                opacity: 0;
                color: var(--theme-deafult);
                background-color: lighten($primary-color, 25%);
                //box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
              }
          
              &:hover {
                &:after {
                  opacity: 1;
                  transform: scale(1);
                  transition: all 0.3s ease;
                }
          
                span {
                  opacity: 1;
                  right: 56px;
                  transform: scale(1);
                  transition: all 0.3s ease;
                }
              }
              &.active {
                img {
                  filter: initial;
                  transition: 0.5s;
                }
              }
            }
          }
        }
        .tab-content-toma {
          height: 458px;
        }
  
        
        .border-tab-toma {
          border-radius: 0;
          &.nav-tabs {
            .nav-item {
              .nav-link {
                border-bottom: none;
                border-right: 2px solid transparent;
                border-top-right-radius: 0;
                &.active, &.show, &:focus {
                  border-right: 2px solid var(--theme-deafult);
                  border-bottom: none;
                }
              }
            }
          }
        }
        .auth-content-toma {
          text-align: center;
          p{
            margin: 0 auto 15px auto;
            width: 90%;
            letter-spacing: 0.1px;
            font-family: 'Lato';
            font-size: 1.1rem;
            line-height: 1.2;
          }
  
          .back-login-toma{
            margin: 20px auto;
            color: #46AFE5;//gato
            font-size: 1rem;
            &:hover{
              cursor: pointer;
            }
          }
          .incorrect-mail-toma{
            text-align: start;
            width: 90%;
            color: #E2231A;
            margin: 0 auto 5px auto;
            font-size: 0.9rem;
          }
          button{
            width: 50%;//90
            margin-top: 10px;
          }
          
        }
      }
    }
  }
}

.show-hide-toma {
  position: absolute;
  margin-top: 15px;
  right: 20px;
  transform: translateY(-50%);

  span {
    cursor: pointer;
    font-size: 13px;
    color: var(--theme-deafult);

    &.show {
      &:before {
        font-family: FontAwesome;
        color: #b42b80;
        content: "\f070 ";
      }
    }

    &:before {
      font-family: FontAwesome;
      color: #a92bb4;
      content: "\f06e";
    }
  }
}


.modal-login-toma{

  .modal-dialog-toma{
    max-width: 60%;
    min-width: 590px;
  }
  .modal-content-toma{
    font-family: 'Comfortaa';
    background-color: #ffffff;
    border: none;
    padding: 20px;
    height: 500px;
    .cancel-icon{
      width: 1.8%;
      margin: 0 0 0 auto;
      &:hover{
        cursor: pointer;
      }
    }
  
    .modal-header-toma{
      border: none;
      .modal-title-toma{
        text-align: center;
        font-weight: bold;
        margin: 0 auto;
        width: auto;
        font-size: 1.3rem;
        .subtitle{
          font-family: 'Lato';
          display: inline;
          font-weight: lighter;
          font-size: 1.9rem;
        }
      }
    }
  
    .modal-body-toma{
      width: 100%;
      text-align: center;
      font-family: 'Lato';
  
      p{
        margin: 0 auto;
        width: 80%;
        font-size: 1rem;
      }
      .row{
        margin: 20px auto;
        height: 75px;
        justify-content: center;
        .col{
          padding: 0;
          margin-inline: 8px;
          flex-grow: 0;
          .text-center{
            background-color: #EDEEEF;
            height: 100%;
            width: 70px;
          }
        }
      }
    }
  
    .modal-footer{
      border: none;
  
      button{
        margin: 0 auto;
        color: white;
        background-color: #b42ba2;
        font-weight: bold;
        font-size: 1.2rem;
        padding: 12px 35px;
        box-shadow: 0 5px 7px rgba(0, 0, 0, 0.3);
      }
    }
  
    .request-new-code{
      text-align: center;
      font-family: 'Lato';
      color: #b42b80;
      font-size: 0.9rem;
      margin-block: 10px;
      &:hover{
        cursor: pointer;
      }
    }
  
    .incorrect-code{
      font-family: 'Lato';
      color: #E2231A;
      margin: 0 auto;
      font-size: 0.9rem;
    }
  }
}





//** end toma **//




@media screen and (max-width: 1360px) {
  .login-main-container{
    .col-xl-4{
      .left-container{
        .subtitle{
          font-size: 1.9rem;
        }
        .image-left-container{
          margin-top: 20%;
        }
      }
    }
  }
}

@media screen and (max-width: 1199.5px) {
  .image-left-container{
    display: none;
  }
  .login-main-container{
    .col-xl-4{
      height: 100px;
      .left-container{
        text-align: center;
        height: 100%;
        padding: 5px;
        .title{
          margin-block: 0;
          font-size: 1.5rem;
          img{
            width: 5.2%;
            min-width: 55px;
          }
        }
        .subtitle{
          display: none;
        }
        .image-left-container{
          display: none;
          img{
            display: none;
          }
        }
      }
    }
    .login-card{
      margin-top: 50px;
      padding: 0;
      min-height: 0;
      .login-main{
        width: 60%;
        min-width: 620px;
      }
    }
  }
}

@media screen and (max-width: 1130px){
  .modal-login{
    .modal-content{
      .modal-header{
        .modal-title{
          font-size: 1.1rem;
          .subtitle{
            font-size: 1.6rem;
          }
        }
      }
      .modal-body{
        flex:0;
        p{
          width: 85%;
          font-size: 0.8rem;
        }
        .row{
          .col{
            .text-center{
              width: 63px;
            }
          }
        }
      }
      .incorrect-code{
        font-size: 0.7rem;
      }
    }
  }
  
}

@media screen and (max-width: 575.99px){
  .login-card .login-main {
    min-width: 280px !important;
    width: 280px !important;
    padding: 20px !important;
  }
}
/**=====================
    25. Authentication CSS Ends
==========================**/