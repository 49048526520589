
.ecommerce-widgets .ecommerce-box.light-bg-in1 {
    background-color: rgba(127, 215, 55, 0.15) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}

.ecommerce-widgets .ecommerce-box.light-bg-in2 {
    background-color: rgba(250, 107, 5, 0.15) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}

.ecommerce-widgets .ecommerce-box.light-bg-in3 {
    background-color: rgb(233 128 215 / 48%) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}